.jsa-icon{
    border: $standard-border;
    border-radius: $standard-radius;
    box-shadow: 2px 2px #D3D3D3;
    cursor: pointer;
}

.jsa-icon:hover{
    box-shadow: 0px 0px;
    background-color: $light-grey;
}

.jsa-icon:active{
    box-shadow: 0px 0px 2px 2px #D3D3D3;
    background-color: $light-grey;
}