@import './colors';
@import './toggle.scss';
@import './react-widgets/react-widgets.scss';
@import './TreeMenu/style.scss';
@import './floating.scss';
@import './Variables.scss';
@import './TimeSheets.scss';
@import './Account.scss';
@import './budget.scss';
@import './checkboxes.scss';
@import './signature.scss';
@import './Admin.scss';
@import './noteView.scss';
@import './headerdropdown.scss';
@import './navbar.scss';
@import './jsaform.scss';

html,
body {
  color: black;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
}

a {
  color: $secondary-light;
}

a:hover {
  color: $secondary-dark;
  text-decoration: none;
}

input::placeholder {
  color: $placeholder-color;
}

.white-bg {
  background-color: $white;
}

.app-container {
  position: relative;
  background-color: white;
  min-height: 100vh;
  width: 100%;
}

.app-container-image {
  background: url('/images/city.png') no-repeat;
  position: relative;
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.display-linebreak {
    white-space: pre-line;
}

.enc-select {
    background-color: $light-grey;
    color: $black;
    padding: 8px;
    border-radius: 2px;
    border: 1px solid $grey;
    margin-left: 10px;
    margin-right: 10px;
}

.enc-button {
  background-color: $secondary-dark;
  color: $font-light;
  border: 0px;
  border-radius: $standard-radius;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.enc-button:hover {
  background-color: $secondary-light;
}

.enc-button:disabled {
  cursor: not-allowed;
}

.enc-button:active {
  background-color: $secondary-light;
}

.enc-toggle-button {
  background-color: $grey;
  color: $font-dark;
  border: 0px;
  border-radius: $standard-radius;
  padding: 8px 14px 8px 14px;
  font-weight: bolder;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.enc-toggle-button:hover {
  background-color: $accent-light;
}

.enc-toggle-button:disabled {
  cursor: not-allowed;
}

.enc-toggle-button:not(:disabled):not(.disabled).active {
  background-color: $accent;
}

.enc-toggle-button.disabled {
  background-color: $light-grey;
  cursor: not-allowed;
}

.enc-textarea {
  background-color: $light-grey;
  color: $black;
  padding: 8px;
  border-radius: $standard-radius;
  border: 1px solid $grey;
  transition: 0.3s;
  height: 190px;
  width: 100%;
}

.enc-input {
  background-color: $light-grey;
  color: $black;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid $grey;
  transition: 0.3s;
  height: 38px;
}

.enc-input:disabled {
  background-color: #F2F2F2;
  color: #555555;
  cursor: not-allowed;
}

.enc-input-100 {
  @extend .enc-input;
  width: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  padding-top: 10px
}

.clickable {
  cursor: pointer;
}

.denied {
  color: #808080;
  cursor: not-allowed;
}

.clickable:hover {
  color: $primary;
}

.fake-link {
  cursor: pointer;
  color: $secondary-light;
}

.fake-link:hover {
  color: $secondary-dark;
}

.enc-cb {
  border-bottom: 1px solid $border-color;
}

.p1-border {
  border: $standard-border;
  border-radius: $standard-radius;
}

.border-left {
  border-left: $standard-border;
}

.section-head {
  background-color: $primary-dark;
  color: $font-light;
}

.section-subhead {
  background-color: $grey;
  color: $font-dark;
}

.section-head-unused {
  color: $font-light;
  background-color: #6c757d;
}

.enc-comment-list:first-child {
  padding-top: .25rem;
  padding-bottom: .25rem;
  background-color: $primary-dark;
  color: $font-light;
  font-size: 1.5rem;
}

.enc-list:first-child {
  padding-top: .25rem;
  padding-bottom: .25rem;
  background-color: $primary-light;
  color: $font-light;
  font-size: 1.5rem;
}

.enc-list:nth-child(even) {
  background-color: white;
  border-bottom: thin solid lightgrey;
  margin-top: .25rem;
  margin-bottom: .25rem;
  color: $font-dark;
}

.enc-list:nth-child(2n+3) {
  background-color: white;
  border-bottom: thin solid lightgrey;
  padding-top: .25rem;
  padding-bottom: .25rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  color: $font-dark;
}

.enc-data-list:first-child {
  padding-top: .25rem;
  padding-bottom: .25rem;
  background-color: $primary-light;
  color: $font-light;
  font-size: 1.5rem;
}

.enc-data-list:nth-child(2) {
  padding-top: .25rem;
  padding-bottom: .25rem;
  background-color: $grey;
}

.enc-data-list:nth-child(2n+4) {
  background-color: white;
  border-bottom: thin solid lightgrey;
  margin-top: .25rem;
  margin-bottom: .25rem;
  color: $font-dark;
}

.enc-data-list:nth-child(2n+3) {
  background-color: white;
  border-bottom: thin solid lightgrey;
  padding-top: .25rem;
  padding-bottom: .25rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  color: $font-dark;
}

.enc-budget-list {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.enc-budget-list:first-child {
  background-color: $primary-light;
  color: $font-light;
  font-size: 1.5rem;
}

.enc-budget-list:nth-child(2) {
  background-color: $grey;
}

.enc-budget-list:nth-child(n+3) {
  background-color: white;
  border-bottom: thin solid lightgrey;
  color: $font-dark;
}

.enc-budget-list:nth-child(n+3):hover {
  background-color: $light-grey;
}

.enc-budget-col {
  border-left: $standard-border
}
.enc-budget-col svg{
    vertical-align:bottom;
}


.click-list {
    cursor: pointer;
    color: $font-dark;
}

.click-list:hover {
  background-color: $light-grey;
}

.click-list:first-child {
  background-color: $primary-light;
  color: $font-light;
  cursor: default;
}

.sep-2px-bt {
  border-top: 2px solid $border-color;
  padding-top: 15px;
}

.profile-image {
  width: 130px;
  border: 0px;
  border-radius: 100%;
}

.profile-top-nti {
  display: inline-block;
  text-align: center;
  border: $standard-border;
  border-radius: $standard-radius;
  padding: 8px;
  max-width: 200px;
}

.enc-alert {
  background-color: $light-grey;
  border: $standard-border;
  border-radius: $standard-radius;
}

.breadcrumb {
  color: $font-dark;
  background-color: #FFFFFF;
  padding: 0;
  margin: 0;
}

.enc-active-tab {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff
}

.divider {
  width: 100%;
  border-bottom: $standard-border;
}

.shaded-list {
  background-color: $grey;
}

.survey-section{
  margin-top: 8px;
  margin-bottom: 64px;
}

.survey-head{
  background-color: $primary-light;
  color: $font-light;
}

.survey-subhead {
  margin-top: 8px;
  margin-bottom: 4px;
  background-color: $grey;
  color: $font-dark;
}

.survey-head-unused {
  color: $font-light;
  background-color: #6c757d;
}

.num-header {
  text-align: center;
  vertical-align: middle;
  font-size: 50px;
  color: $font-light;
  background-color: $primary-light;
}

.num-header-unused {
  text-align: center;
  vertical-align: middle;
  font-size: 50px;
  color: $font-light;
  background-color: #6c757d;
}

.warning {
  color: #f00;
}

/// Timesheets 
.wrapper {
  display: grid;
  grid-template-columns: 75px 75px 75px;
  grid-template-rows: 75px 75px 75px;
  grid-gap: 10px;
  grid-auto-rows: minmax(auto, auto);
}

.button-holder {
  float: left;
}

.ft-textarea {
  background-color: $light-grey;
  color: $black;
  padding: 8px;
  border-radius: $standard-radius;
  border: 1px solid $grey;
  transition: 0.3s;
  height: 14em;
  width: 100%;
}

.error-message {
  color: red;
  font-weight: bold;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}

.itemHeaderENCLight {
  background-color: $primary-light;
  color: white;
  cursor: default;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.remove-child-outline>* {
  outline: none;
}


.primary-light-bg {
  background-color: $primary-light;
  color: white;
  padding: 0.75rem 1.25rem;
}




.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -999;
}

.inputfile+label {
  background-color: $secondary-dark;
  color: $font-light;
  border: 0px;
  border-radius: $standard-radius;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: 400;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.inputfile+label {
  cursor: pointer;
  /* "hand" cursor */
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: $secondary-light;
}

.inputfile:focus+label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.date-picker-fix, 
.date-picker-fix > div.react-datepicker-wrapper, 
.date-picker-fix > div.react-datepicker-wrapper > div.react-datepicker__input-container,
.date-picker-fix > div.react-datepicker-wrapper > div.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.attachment-container{
    position:relative;
}
.attachment-camera {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 999999;
    padding: 26px;
}
.attachment-camera-preview {
    text-align: center;
    margin-bottom:2px;
}
.attachment-camera-preview img {
    margin-bottom: 6px;
}
.attachment-container .filepond--list {
    left: 62px;
}
.attachment-container video {
    width: 100%;
}

.search-result {
    white-space: pre;
}