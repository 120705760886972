/// Timesheets 
.wrapper {
    display: grid;
      grid-template-columns: 75px 75px 75px;
    grid-template-rows: 75px 75px 75px;
    grid-gap: 10px;
    grid-auto-rows: minmax(auto, auto);
  }
  
  .button {
    width: 75px;
    height: 75px;
    border-radius: 2px;
    background-color: #1698a8;
    text-align: center;
    vertical-align: middle;
    line-height: 75px;
    font-family: Gotham, "Helvetica Neue", Helvetica, Arial, "sans-serif";
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    transition: background-color .15s, transform .25s;
  }
  
  .button:hover{
    background-color: #5cbbc8;
  }
  .button:active{
    background-color: #027c8d;
    transform: scale(1.08);
  }
  .one {
    grid-column: 1;
    grid-row: 1;
  }
  .two { 
    grid-column: 2;
    grid-row: 1;
  }
  .three {
    grid-column: 3;
    grid-row: 1;
  }
  .four {
    grid-column: 1;
    grid-row: 2;
  }
  .five {
    grid-column: 2;
    grid-row: 2;
  }
  .six {
    grid-column: 3;
    grid-row: 2;
  }
  .seven {
    grid-column: 1;
    grid-row: 3;
  }
  .eight {
    grid-column: 2;
    grid-row: 3;
  }
  .nine {
    grid-column: 3;
    grid-row: 3;
  }
  .zero {
    grid-column: 2;
    grid-row: 4;
  }
  .backspace {
    grid-column: 1;
    grid-row: 4;
  }
  .enter {
    grid-column: 3;
    grid-row: 4;
  }
  