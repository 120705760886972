@import './Variables.scss';

.floating-label {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding-top: 5px;
    color: $placeholder-color;
    position: relative;
  }

  .floating-top-text {
    padding: 2px;
  }
  
  .floating-label + .floating-label {
    margin-top: 0.5em;
  }
  
  .floating-label input
  {
    width: 100%;
    border: $standard-border;
    border-radius: $standard-radius;
    background-color: $light-grey;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 12px 0 8px 2px;
  }

  .floating-label textarea {
    width: 100%;
    border: $standard-border;
    border-radius: $standard-radius;
    background-color: $light-grey;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 12px 0 8px 2px;
    height: 180px;
  }
  
  .floating-label input:focus,
  .floating-label textarea:focus {
    border-color: $primary;
  }
  
  .floating-label input:focus + span,
  .floating-label textarea:focus + span,
  .floating-label.floating span {
    font-size: 0.8rem;
    padding: 4px 0 0 5px;
  }
  
  .floating-label input:focus:not(:focus-visible),
  .floating-label textarea:focus:not(:focus-visible) {
    outline: none;
  }
  
  .floating-label span {
    box-sizing: border-box;
    font-size: 1rem;
    left: 0;
    padding: 14px 0 13px 14px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: font-size 200ms, padding 200ms;
    z-index: 1;
  }