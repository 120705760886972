.nav-rp {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .admin-tab {
    padding: 8px 10px;
    margin-right: 5px;
    border-radius: .25rem .25rem 0 0;
    color: $font-dark;
    background: $light-grey;
    text-align: center;
    cursor: pointer;
    transition: all 200ms;
    border-top: $standard-border;
    border-right: $standard-border;
    border-left: $standard-border;
  }
  
  .admin-tab:hover {
    background: $primary;
    color: $font-light;
  }
  
  .active-tab {
    background: $primary-dark;
    color: $font-light;
  }
  
  .role-button {
    cursor: pointer;
    transition: all 200ms;
    width: 100%;
    color: $font-dark;
    border-top: $standard-border;
  }

  .role-button:last-child{
    border-bottom: $standard-border;
  }
  
  .role-button:hover {
    background: $primary;
    color: $font-light;
  }
  
  .active-role {
    background: $grey;
    color: $font-dark;
  }
  
  .role-bb {
    border-bottom: $standard-border;
  }
  
  .role-bt {
    border-top: $standard-border
  }