.navbar-dark {
    background-color: #2C2C2C;
    color: white;
}

.navbar-dark a:link {
    color: white;
}

.navbar-dark  a:visited {
    color: white;
}

.navbar-dark a:hover {
    color: #1698A8;
}

.navlink-spacing {
    padding: 0.25rem 1rem;
}

.content-wrap {
    padding-bottom: 2rem;    /* Footer height */
  }

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2rem;            /* Footer height */
    background-color: #2C2C2C;
    text-align: center;
    color: white;
    font-size: .8rem;
}

.footer-content{
    display: inline-block;
    margin:auto;
}

.header{
    width: 100%;
    background-color: white;
}

.header-image {
    max-height: 80px;
}
