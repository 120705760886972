.account-list > div:focus{
    outline: none;
  }
  .account-bg-1{
    background-color: #FFFFFF;
  }
  .account-bg-2{
    background-color: #F2F2F2;
  }
  .account-bg-3{
    background-color: #E6E6E6;
  }
  .account-bg-2:hover{
    background-color: #E6E6E6;
  }