//Company Color Brand
$white: #FFFFFF;
$primary: #1698A8;
$primary-dark: #027C8D;
$primary-light: #5CBBC8;
$secondary: #2662B5;
$secondary-dark: #104A9C;
$secondary-light: #3780E6;
$accent: #ff8A1D;
$accent-dark: #E66E00;
$accent-light: #FFBA34;
$danger: red;



//grey shades
$grey: #E6E6E6;
$light-grey: #F2F2F2;
$dark-grey: #BEBEBE;
$black: #1A1A1A;

//Border Color
$border-color: rgba(0, 0, 0, 0.125);

//fonts
$font-dark: black;
$font-light: white;

$placeholder-color: #646464;