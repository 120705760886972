.budget-col{
    padding: .5em 0 .5em 0;
    text-align: center;
    vertical-align: center;
    border-right: $standard-border;
  }
  .budget-col:last-child{
    border-right: 0px;
  }
  
  .budget-table{
    border: $standard-border;
  }
  
  .budget-header-row{
    padding-top:.25rem;
    padding-bottom:.25rem;
    background-color:rgb(91, 91, 91);
    color: $font-light;
    font-size:1.25rem;
  }
  
  .budget-table:first-child{
    padding-top:.25rem;
    padding-bottom:.25rem;
    background-color: #5cbbc8;
    font-size:1.25rem;
    color:white;
  }
  .budget-table:nth-child(even){
    padding-top:.25rem;
    padding-bottom:.25rem;
    background-color:white;
    color: black;
    font-size:1rem;
  }
  
  .budget-table:nth-child(2n+3){
    padding-top:.25rem;
    padding-bottom:.25rem;
    background-color:white;
    color: black;
    font-size:1rem;
  }
  .budget-hour{
    border-bottom: $standard-border;
  }