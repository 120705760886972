.signature-wrapper{
    width: 100%;
    height: 14em;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: $standard-border;
    border-radius: $standard-radius;
    background-color: $light-grey;
  }
  
  .signature-style {
    border-top: $standard-border;
    width: 100%;
    height: 100%;
  }
  