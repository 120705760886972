
.noteViewContainer{
    min-height: 300px;
    background-color: #aaaaaa;
    padding: 8px;
    border-radius: 8px;
}

.note-body{
    background-color: white;
    color: black;
    border: $standard-border;
    border-radius: $standard-radius;
}

.note-container{
    background-color: $dark-grey;
}