/* Password CSS */
.pw-row {
    display: flex;
}

.change-password-container{
    align-self: center;
    max-width: 600px;
    padding-top: 20px;
    margin: auto;
}

.change-pw-name{
    width: 12em;
}

.change-pw-field{
    width: calc(100% - 15em);
}

.pw-error {
    display: inline-block;
    width: 100%;
    height: 10px;
    text-align: center;
    color: red;
}

/* Phone and Email Lists */

.enc-input-pe {
    background-color: #D9D9D9;
    color: #222222;
    padding: 2px;
    border-radius: 2px;
    border: 1px solid #2C2C2C;
    transition: 0.3s;
    height: 30px;
    width: 100%;
    outline-color: #e64c38;
    outline-style: none;
  }

.enc-button-pe {
    background-color: #1698A8;
    color: #ffffff;
    border: 1px solid #1698A8;
    border-radius: 2px;
    padding: 2px 4px 2px 4px;
    font-weight: bolder;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }

  .enc-dropdown-pe
{
    background-color: #D9D9D9;
    color: #222222;
    padding: 2px;
    border-radius: 2px;
    border: 1px solid #2C2C2C;
}