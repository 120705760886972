@import './colors';
@import './Variables.scss';


.user-image{
    width: 80px;
}

/* dropdown menu */
.dd-wrapper {
    width: 100%;
    max-width: 350px;
}
.dd-header {
    cursor: pointer;
    position: relative;
}
.dd-header:hover {
    background-color: $light-grey;
}

.dd-list {
    width: 90%;
    z-index: 10;
    position: absolute;
    border: $standard-border;
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
            box-shadow: 0 2px 5px -1px #e8e8e8;
    padding: 15px 0;
    max-height: 215px;
    -webkit-overflow-scrolling: touch;
    background-color: $white;
}

.dd-list-item {
    width: 100%;
    padding: 8px 10px;
    line-height: 1.6rem;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

.dd-list-item a {
    color: inherit;
}
.dd-list-item:hover{
    color: $white;
    background-color: $primary; 
  }